import { useEffect, useCallback, useState } from 'react';
import {
  MdDeliveryDining,
  MdFavorite,
  MdFavoriteBorder,
  MdOutlineEmail,
} from 'react-icons/md';
import { useParams, useNavigate, Link } from 'react-router-dom';

import { message } from 'antd';
import axios from 'axios';
import { Button } from 'components';
import { env } from 'configs';
import { Layout } from 'containers';
import { useUser, useCities } from 'hooks';
import api from 'services/api';

import { StoreDetails } from './interfaces';
import * as S from './styles';

export function Store() {
  const [shop, setShop] = useState<StoreDetails>({} as StoreDetails);
  const [loading, setLoading] = useState(true);
  const [hasBookmark, setHasBookmark] = useState(false);
  const [hasNotification, setHasNotification] = useState(false);
  const [notificationId, setNotificationId] = useState(null);
  const [bookmarkId, setBookmarkId] = useState(null);
  const { id } = useParams();
  const { user } = useUser();
  const navigate = useNavigate();
  const { handleSelectCity } = useCities();

  const refreshPage = () => {
    window.location.reload();
  };

  const handleGetShopDetails = useCallback(async (id: string) => {
    try {
      const { data } = await api.get(`shop/${id}`);

      setShop(data);
      setLoading(false);

      handleSelectCity(`${data.city}, ${data.state}`);

      if (user) {
        handleValidateBookmark(user.id, data.id);
        handleValidateNotifications(user.id, data.id);
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        navigate('/');
      } else {
        console.log(err);
        message.error('Ocorreu um erro!');
      }
    }
  }, []);

  const handleReceiveEmail = useCallback(async () => {
    if (!user) {
      navigate('/acessar');

      message.info(
        'Para receber notificações você precisa acessar a sua conta',
      );
    } else {
      try {
        await api.post(`user/notification`, {
          user_id: user.id,
          shop_id: shop.id,
        });
        message.success('Notificação ativada com sucesso!');
      } catch (err) {
        message.error('Ocorreu um erro ao tentar ativar as notificações');
      }
    }
  }, [user, shop]);

  const handleAddBookmark = useCallback(async () => {
    if (!user) {
      navigate('/acessar');

      message.info(
        'Para adicionar aos favoritos você precisa acessar a sua conta',
      );
    } else {
      try {
        await api.post(`user/bookmark`, {
          user_id: user.id,
          shop_id: shop.id,
        });
        message.success('Favorito cadastrado com sucesso!');
        refreshPage();
      } catch (err) {
        message.error('Ocorreu um erro ao tentar adicionar aos favoritos');
      }
    }
  }, [user, shop]);

  const handleRemoveEmail = useCallback(async () => {
    try {
      await api.delete(`user/notification/${notificationId}`);
      message.success('Notificação removida com sucesso!');
      setNotificationId(null);

      document.location.reload();
    } catch (err) {
      message.error('Ocorreu um erro ao tentar ativar as notificações');
    }
  }, [notificationId]);

  const handleRemoveBookmark = useCallback(async () => {
    try {
      await api.delete(`user/bookmark/${bookmarkId}`);
      message.success('Favorito removida com sucesso!');
      setBookmarkId(null);

      document.location.reload();
    } catch (err) {
      message.error('Ocorreu um erro ao tentar ativar as notificações');
    }
  }, [bookmarkId]);

  const handleValidateBookmark = useCallback(
    async (userId: string, shopId: string) => {
      try {
        const { data } = await api.get(
          `/user/${userId}/shop/${shopId}/bookmark`,
        );

        if (data.id) {
          setHasBookmark(true);
          setBookmarkId(data.id);
        }
      } catch (err) {
        // if (axios.isAxiosError(err)) {
        //   if (err.response?.status === 404) {
        //     setHasBookmark(false);
        //   } else {
        //     message.error(err.response?.data);
        //   }
        // } else {
        //   message.error('Ocorreu um erro ao tentar verificar favoritos');
        // }
      }
    },
    [],
  );

  const handleValidateNotifications = useCallback(
    async (userId: string, shopId: string) => {
      try {
        const { data } = await api.get(
          `/user/${userId}/shop/${shopId}/notification`,
        );

        if (data.id) {
          setHasNotification(true);
          setNotificationId(data.id);
        }
      } catch (err) {
        // if (axios.isAxiosError(err)) {
        //   if (err.response?.status === 404) {
        //     setHasNotification(false);
        //   } else {
        //     message.error(err.response?.data);
        //   }
        // } else {
        //   message.error('Ocorreu um erro ao tentar verificar favoritos');
        // }
      }
    },
    [],
  );

  useEffect(() => {
    if (id) {
      handleGetShopDetails(id);
    } else {
      navigate('/');
    }
  }, []);

  return (
    <Layout>
      {!loading && (
        <>
          <S.StoreDetails>
            <S.StoreDescription>
              <div>
                <img
                  src={`${env.apiUrl}/images/shops/${shop.image}`}
                  alt={shop.name}
                />
                <span>
                  <b>Sobre: </b>
                  {shop.description}
                </span>
                <br />
                <span>
                  <b>Horário de funcionamento: </b>
                  {shop.opening_time} | {shop.closing_time}
                </span>
                <span>
                  <b>Métodos de pagamento: </b>
                  {shop.payment_methods}
                </span>
                <span>
                  <b>Telefone: </b>
                  {shop.phone}
                </span>
                {shop.link && (
                  <span>
                    <b>Site: </b>
                    <a href={shop.link} target="_blank" rel="noreferrer">
                      {shop.link}
                    </a>
                  </span>
                )}
                {shop.delivery === 1 && (
                  <S.DeliveryButtonWrapper>
                    <Button
                      onClick={() =>
                        window.open('https://wa.link/e70rf4', '_blank')
                      }
                    >
                      <MdDeliveryDining />
                      Pedir agora
                    </Button>
                  </S.DeliveryButtonWrapper>
                )}
              </div>

              <S.NotificationsButtonContainer>
                {hasNotification ? (
                  <button onClick={() => handleRemoveEmail()}>
                    <MdOutlineEmail />
                    <span>Remover notificação</span>
                  </button>
                ) : (
                  <button onClick={() => handleReceiveEmail()}>
                    <MdOutlineEmail />
                    <span>Receber notificações</span>
                  </button>
                )}

                {hasBookmark ? (
                  <button onClick={() => handleRemoveBookmark()}>
                    <MdFavorite />
                    <span>Remover favorito</span>
                  </button>
                ) : (
                  <button onClick={() => handleAddBookmark()}>
                    <MdFavoriteBorder />
                    <span>Adicionar aos favoritos</span>
                  </button>
                )}
              </S.NotificationsButtonContainer>
            </S.StoreDescription>
            <S.MapContainerDesktop>
              <iframe
                title="map"
                src={shop.map_link}
                style={{ border: 0 }}
                loading="lazy"
              />

              <span>
                {`${shop.address}, ${shop.number}, ${shop.city} - ${shop.state}, ${shop.country}`}
              </span>
            </S.MapContainerDesktop>
          </S.StoreDetails>
          <S.FlyerContainer>
            {shop.flyers.map(flyer => (
              <S.Flyer key={flyer.id}>
                <S.ImageContainer>
                  <S.OpenButton
                    onClick={() => navigate(`/panfleto/${flyer.id}`)}
                  >
                    Folhear panfleto
                  </S.OpenButton>

                  <S.Image
                    src={`${env.apiUrl}/images/flyers/${flyer.id}/${flyer.images[0]?.file}`}
                    alt={shop.name}
                  />
                </S.ImageContainer>
                {/* <S.StoreName>{shop.name}</S.StoreName> */}
                <Link to={`/panfleto/${flyer.id}`}>Abrir panfleto</Link>
              </S.Flyer>
            ))}
          </S.FlyerContainer>
          <S.MapContainerMobile>
            <iframe
              title="map"
              src={shop.map_link}
              style={{ border: 0 }}
              loading="lazy"
            />

            <span>
              {`${shop.address}, ${shop.number}, ${shop.city} - ${shop.state}, ${shop.country}`}
            </span>
          </S.MapContainerMobile>
        </>
      )}
    </Layout>
  );
}
