import {
  breakpoints,
  font,
  primaryColors,
  secondaryColors,
} from 'common/styles/theme';
import styled from 'styled-components';

export const StoreDetails = styled.div`
  display: grid;
  align-items: flex-start;
  grid-template-columns: 3fr 1fr;
  gap: 6rem;

  @media (max-width: ${breakpoints.large}) {
    grid-template-columns: 1fr;
  }
`;

export const StoreDescription = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  img {
    width: 15rem;
    margin-bottom: 3.2rem;
  }

  span {
    display: block;
    font-size: 1.5rem;
    color: ${secondaryColors.grey200};
    max-width: 61.7rem;
  }

  b {
    color: #222;
  }
`;

export const NotificationsButtonContainer = styled.div`
  margin-top: 3.2rem;

  display: flex;
  align-items: center;
  gap: 3rem;

  button {
    display: flex;
    align-items: center;
    background: transparent;
    border: 0;

    span {
      color: ${secondaryColors.orange100};
    }

    svg {
      color: ${secondaryColors.orange100};
      margin-right: 1rem;
    }
  }

  @media (max-width: ${breakpoints.small}) {
    span {
      font-size: 1.4rem;
    }
  }
`;

export const MapContainerDesktop = styled.div`
  width: 44.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  button {
    width: 100%;
    max-width: 19.8rem;
    background-color: ${secondaryColors.orange100};

    margin-top: 3.2rem;
    height: 4.7rem;
    border-radius: 0.5rem;

    span {
      font-size: 1.3rem;
    }

    :hover {
      background-color: ${secondaryColors.orange200};
    }
  }

  iframe {
    width: 44.3rem;
    height: 26.5rem;
    border-radius: 0.5rem;
    border: 0.1rem solid ${secondaryColors.grey300};

    @media (max-width: ${breakpoints.small}) {
      width: 100%;
      min-height: 26.5rem;
    }
  }

  > span {
    width: 44.3rem;
    display: block;
    font-size: 1.3rem;
    color: ${secondaryColors.grey200};
    max-width: 61.7rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: ${breakpoints.large}) {
    display: none;
  }
`;

export const MapContainerMobile = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 3rem;
  width: 100%;
  overflow: hidden;

  button {
    width: 100%;
    max-width: 19.8rem;
    background-color: ${secondaryColors.orange100};

    margin-top: 3.2rem;
    height: 4.7rem;
    border-radius: 0.5rem;

    span {
      font-size: 1.3rem;
    }

    :hover {
      background-color: ${secondaryColors.orange200};
    }
  }

  iframe {
    border-radius: 0.5rem;
    border: 0.1rem solid ${secondaryColors.grey300};

    width: 100%;
    min-height: 26.5rem;
  }

  > span {
    width: 44.3rem;
    display: block;
    font-size: 1.3rem;
    color: ${secondaryColors.grey200};
    max-width: 61.7rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (min-width: ${breakpoints.large}) {
    display: none;
  }
`;

export const ExpirationContainer = styled.div`
  margin-top: 3.2rem;

  span {
    display: block;

    b {
      color: ${secondaryColors.orange100};
    }
  }

  span + span {
    margin-top: 1.6rem;
  }
`;

export const ShareButton = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  border: 0;

  margin-top: 2.8rem;

  span {
    color: ${secondaryColors.orange100};
    font-weight: ${font.medium};
  }

  svg {
    color: ${secondaryColors.orange100};
    margin-right: 1rem;
    font-size: 2rem;
  }
`;

export const FlyerContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20.2rem, 1fr));
  gap: 2.7rem;
  justify-content: space-between;

  margin-top: 3.2rem;

  @media (max-width: ${breakpoints.small}) {
    grid-template-columns: repeat(auto-fill, calc((100% / 2) - 1.2rem));
    margin-top: 2.4rem;

    gap: 1.2rem;
  }
`;

export const OpenButton = styled.div`
  position: absolute;
  width: 100%;
  height: 30px;
  cursor: pointer;

  background-color: ${secondaryColors.orange100};
  transition: 0.3s;
  color: #fff;
  align-items: center;
  justify-content: center;
`;

export const Flyer = styled.div`
  position: relative;
  max-width: 20.2rem;
  width: 100%;

  a {
    text-decoration: none;
    font-size: 1.6rem;
    font-weight: ${font.medium};
    color: ${secondaryColors.green100};

    display: block;
    margin-top: 1rem;

    @media (max-width: ${breakpoints.small}) {
      font-size: 1.4rem;
    }
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 26.9rem;

  margin-bottom: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;

  ${OpenButton} {
    display: none;
  }

  :hover {
    ${OpenButton} {
      display: flex;
    }
  }

  @media (max-width: ${breakpoints.small}) {
    margin-bottom: 1rem;
    height: 23rem;
  }
`;

export const Image = styled.img`
  width: 20.2rem;
  height: 26.9rem;

  border-radius: 0.4rem;

  @media (max-width: ${breakpoints.small}) {
    width: 100%;
    height: 23rem;
  }
`;

export const StoreName = styled.span`
  display: block;
  font-weight: ${font.bold};
  font-size: 1.8rem;
  color: ${primaryColors.black};

  margin-top: 1rem;

  @media (max-width: ${breakpoints.small}) {
    margin-top: 0.5rem;
    font-size: 1.3rem;
  }
`;

export const DeliveryButtonWrapper = styled.div`
  margin-top: 3.2rem;

  button {
    span {
      color: #fff !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
